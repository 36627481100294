/*
 * File: firebase.js
 * Project: e-auction-demo
 * File Created: Wednesday, 25th August 2021 8:56:15 am
 * Author: Ananda Yudhistira (anandabayu12@gmail.com)
 * -----
 * Last Modified: Saturday, 16th October 2021 9:37:54 am
 * Modified By: Ananda Yudhistira (anandabayu12@gmail.com>)
 * -----
 * Copyright 2021 Ananda Yudhistira, FAN Integrasi Teknologi, PT
 */
import firebase from 'firebase';
import 'firebase/firestore';
import 'firebase/storage';

firebase.initializeApp({
  apiKey: 'AIzaSyAjPT6yWkW98WdYayccgynjBImeVfXIM-U',
  authDomain: 'e-auction-demo.firebaseapp.com',
  projectId: 'e-auction-demo',
  storageBucket: 'e-auction-demo.appspot.com',
  messagingSenderId: '33112740481',
  appId: '1:33112740481:web:245453df4bdb8c7a7e3380',
});

let db = firebase.firestore();
let storage = firebase.storage();

const fb = {
  firebase,
  db,
  storage,
};

export default fb;
