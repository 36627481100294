/*
 * File: Vendor.js
 * Project: e-auction-demo
 * File Created: Monday, 30th August 2021 11:38:29 am
 * Author: Ananda Yudhistira (anandabayu12@gmail.com)
 * -----
 * Last Modified: Saturday, 16th October 2021 11:39:48 am
 * Modified By: Ananda Yudhistira (anandabayu12@gmail.com>)
 * -----
 * Copyright 2021 Ananda Yudhistira, FAN Integrasi Teknologi, PT
 */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// reactstrap components
import {
  Alert,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  Container,
  Row,
  Table,
} from 'reactstrap';
import NumberFormat from 'react-number-format';
import moment from 'moment';

import firebase from 'config/firebase';

const Vendor = (props) => {
  moment.locale('id');
  let { id } = useParams();

  const mainContent = React.useRef(null);

  const [error, setError] = useState('');

  const [email, setEmail] = useState('19137');
  const [password, setPassword] = useState('89948');

  const [isAuth, setAuth] = useState(false);
  const [user, setUser] = useState({});
  const [auction, setAuction] = useState({});

  const [peserta, setPeserta] = useState([]);
  const [penawaran, setPenawaran] = useState([]);
  const [files, setFiles] = useState([]);
  const [newFiles, setNewFiles] = useState([]);
  const [hps, setHps] = useState();

  const [isStarted, setStarted] = useState(false);
  const [isUpload, setUpload] = useState(false);

  useEffect(() => {
    const unsubscribe = firebase.db
      .collection(`auction`)
      .doc(id)
      .onSnapshot((snapshot) => {
        console.log('AUCTION');
        setAuction(snapshot.data());
      });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const unsubscribe = firebase.db
      .collection(`auction`)
      .doc(id)
      .collection(`peserta`)
      .onSnapshot((snapshot) => {
        console.log('PESERTA');
        if (snapshot.size) {
          const data = [];
          snapshot.forEach((doc) => data.push({ ...doc.data(), id: doc.id }));
          setPeserta(data);

          console.log(isAuth);

          if (isAuth || isAuth === 'true') {
            let p = data.find((e) => {
              return e.username === email && e.password === password;
            });

            if (p) {
              setUser(p);
              setAuth(true);
              setPenawaran(p.penawaran);
              setFiles(p.files);

              console.log(p);
            }
          } else {
            console.log(`AUTH: ${isAuth}`);
          }
        } else {
          setPeserta([]);
        }
      });

    return () => {
      unsubscribe();
    };
  }, [isAuth]);

  const doLogin = async () => {
    let user = peserta.find((e) => {
      // return e.username === '42529' && e.password === '812';
      return e.username === email && e.password === password;
    });

    if (user) {
      setUser(user);
      setAuth(true);
      setPenawaran(user.penawaran);
      setFiles(user.files);

      var i = 0;
      user.files.forEach((e) => {
        if (e.url === null || e.url === '') i += 1;
      });

      if (i === 0) setUpload(true);
    } else {
      setError('Username atau password salah');
    }
  };

  const e = () => {
    if (error) {
      return <Alert color="danger">{error}</Alert>;
    } else {
      return null;
    }
  };

  const submitPenawaran = async () => {
    let p15 = auction.hps - hps;
    let q15 = (p15 / auction.hps) * 100;
    let r15 = (auction.finansial / 100) * q15;

    let value = +user.teknis + +r15;

    let p = penawaran;
    p.push(parseInt(hps));
    p.sort((a, b) => {
      return b - a;
    });
    setPenawaran(p);

    await firebase.db
      .collection(`auction`)
      .doc(id)
      .collection(`peserta`)
      .doc(user.id)
      .update({
        penawaran: p,
        bestValue: value > user.bestValue ? value : user.bestValue,
      })
      .then(() => {
        console.log('Success');
      })
      .catch((error) => {
        console.error('Error writing document: ', error);
      });

    if (value > auction.winnerValue) {
      await firebase.db
        .collection(`auction`)
        .doc(id)
        .update({
          winnerValue: value,
          winnerId: user.id,
        })
        .then(() => {
          console.log('Success');
        })
        .catch((error) => {
          console.error('Error writing document: ', error);
        });
    }

    setHps('');
  };

  const calculateTimeLeft = () => {
    let timeLeft = {};

    let endDate = Date.parse(auction.endDate);

    const difference = +endDate - +new Date();

    if (difference > 0) {
      timeLeft = {
        hari: Math.floor(difference / (1000 * 60 * 60 * 24)),
        jam: Math.floor((difference / (1000 * 60 * 60)) % 24),
        menit: Math.floor((difference / 1000 / 60) % 60),
        detik: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  // const [year] = useState(new Date().getFullYear());

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
      let startDate = Date.parse(auction.startDate);

      if (Date.now() > startDate) {
        setStarted(true);
      } else {
        setStarted(false);
      }
    }, 1000);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span>
        {timeLeft[interval]} {interval}{' '}
      </span>
    );
  });

  const handleImageAsFile = (e, name, i) => {
    e.preventDefault();

    const image = e.target.files[0];

    const uploadTask = firebase.storage
      .ref(`/doc/${name}-${user.username}`)
      .put(image);
    //initiates the firebase side uploading
    uploadTask.on(
      'state_changed',
      (snapShot) => {
        console.log(snapShot);
      },
      (err) => {
        console.log(err);
      },
      () => {
        firebase.storage
          .ref('doc')
          .child(`${name}-${user.username}`)
          .getDownloadURL()
          .then((fireBaseUrl) => {
            let data = {
              name,
              url: fireBaseUrl,
            };
            let f = [...files];
            f[i] = data;
            setFiles(f);
          });
      }
    );
  };

  const uploadFile = async () => {
    var i = 0;
    files.forEach((e) => {
      if (e.url === null || e.url === '') i += 1;
    });

    await firebase.db
      .collection(`auction`)
      .doc(id)
      .collection(`peserta`)
      .doc(user.id)
      .update({
        files: files,
      })
      .then(() => {
        console.log('Success');

        if (i === 0) setUpload(true);
      })
      .catch((error) => {
        console.error('Error writing document: ', error);
      });
  };

  const c = () => {
    return isAuth ? (
      !isUpload ? (
        <Card className="shadow">
          <CardHeader className="border-0">
            <Row className="align-items-center">
              <Col md="8">
                <h3 className="mb-0">
                  {user.perusahaan}
                  <br />
                  {auction.name}
                </h3>
              </Col>
            </Row>
          </CardHeader>

          <CardBody>
            <Row>
              <Col md="10">
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col" className="text-center">
                        Nama File
                      </th>
                      <th scope="col" className="text-center">
                        Upload File
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {files.map((p, i) => {
                      return (
                        <tr key={i}>
                          <td>{p.name}</td>
                          <td className="text-center">
                            {p.url === null || p.url === '' ? (
                              <input
                                type="file"
                                onChange={(e) =>
                                  handleImageAsFile(e, p.name, i)
                                }
                              />
                            ) : (
                              <Button
                                color="success"
                                size="md"
                                onClick={() => window.open(p.url, '_blank')}
                              >
                                View
                              </Button>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
            <hr className="my-4" />
            <Row>
              <Col>
                <Button color="success" size="md" onClick={uploadFile}>
                  Submit
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      ) : (
        <Card className="shadow">
          <CardHeader className="border-0">
            <Row className="align-items-center">
              <Col md="8">
                <h3 className="mb-0">
                  {user.perusahaan}
                  <br />
                  {auction.name}
                </h3>
              </Col>
            </Row>
          </CardHeader>

          {!isStarted ? (
            <CardBody>
              <span className="text-center">Menunggu Waktu Auction</span>
            </CardBody>
          ) : (
            <CardBody>
              <Row>
                <Col md="4">
                  <FormGroup>
                    <label className="form-control-label">
                      {penawaran.length < 1
                        ? 'Input harga penawaran awal'
                        : 'Input Harga'}
                    </label>
                    <NumberFormat
                      value={hps}
                      className="form-control"
                      placeholder="Rp. xxx.xxx,xx"
                      thousandSeparator="."
                      decimalSeparator=","
                      prefix={'Rp. '}
                      onValueChange={(values) => {
                        const { value } = values;
                        setHps(value);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    {timerComponents.length ? (
                      <Button
                        className="my-4"
                        color="primary"
                        type="button"
                        onClick={() => {
                          if (hps) {
                            submitPenawaran();
                          }
                        }}
                      >
                        Submit
                      </Button>
                    ) : (
                      <></>
                    )}
                  </FormGroup>
                </Col>
                <Col md="6" className="text-center">
                  <div>
                    <br />
                    {auction.winnerId === user.id ? (
                      <i className="ni ni-trophy ni-2x text-success" />
                    ) : (
                      ''
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col" className="text-center">
                          Waktu
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {penawaran.map((p, i) => {
                        return (
                          <tr key={i}>
                            <td className="text-right">
                              <NumberFormat
                                value={p}
                                displayType={'text'}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                                prefix={'Rp. '}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Col>
                <Col md="6">
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col" className="text-center">
                          Waktu
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-center">
                          <div>
                            {timerComponents.length ? (
                              timerComponents
                            ) : (
                              <span>Time's up!</span>
                            )}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </CardBody>
          )}
        </Card>
      )
    ) : (
      <>
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary shadow border-0">
              <CardHeader className="bg-transparent pb-1">
                <div className="text-muted text-center mt-2 mb-3">
                  <small>Sign in with</small>
                </div>
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-5">
                {e()}

                <Form role="form">
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Username"
                        type="text"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type="password"
                        autoComplete="new-password"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <div className="text-center">
                    <Button
                      className="my-4"
                      color="primary"
                      type="button"
                      onClick={() => doLogin()}
                    >
                      Sign in
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      <div className="main-content" ref={mainContent}>
        <div className="header bg-gradient-info py-7 py-lg-8"></div>
        {/* Page content */}
        <Container className="mt--8 pb-5">{c()}</Container>
      </div>
      {/* <AuthFooter /> */}
    </>
  );
};

export default Vendor;
