/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Index from 'views/Index.js';
import Profile from 'views/pages/Profile.js';
import Login from 'views/pages/Login.js';
import Tables from 'views/pages/Tables.js';
import Icons from 'views/pages/Icons.js';

import Auction from './views/pages/auction/Auction.js';
import AddAuction from './views/pages/auction/AddAuction.js';
import DetailAuction from './views/pages/auction/DetailAuction.js';

var routes = [
  {
    path: '/login',
    name: 'Login',
    icon: 'ni ni-key-25 text-info',
    component: Login,
    layout: '/auth',
    sidebar: false,
  },
  {
    path: '/index',
    name: 'Dashboard',
    icon: 'ni ni-tv-2 text-primary',
    component: Index,
    layout: '/admin',
    sidebar: true,
  },
  // {
  //   path: '/icons',
  //   name: 'Icons',
  //   icon: 'ni ni-planet text-blue',
  //   component: Icons,
  //   layout: '/admin',
  //   sidebar: true,
  // },
  {
    path: '/user-profile',
    name: 'User Profile',
    icon: 'ni ni-single-02 text-yellow',
    component: Profile,
    layout: '/admin',
    sidebar: false,
  },
  // {
  //   path: '/tables',
  //   name: 'Tables',
  //   icon: 'ni ni-bullet-list-67 text-red',
  //   component: Tables,
  //   layout: '/admin',
  //   sidebar: true,
  // },
  {
    path: '/auction/index',
    name: 'Auction',
    icon: 'ni ni-chart-bar-32 text-red',
    component: Auction,
    layout: '/admin',
    sidebar: true,
  },
  {
    path: '/auction/add',
    name: 'Tambah Auction',
    icon: 'ni ni-calendar-grid-58 text-green',
    component: AddAuction,
    layout: '/admin',
    sidebar: false,
  },
  {
    path: '/auction/detail/:id',
    name: 'Detail Auction',
    icon: 'ni ni-calendar-grid-58 text-green',
    component: DetailAuction,
    layout: '/admin',
    sidebar: false,
  },
];
export default routes;
