/*
 * File: auction.js
 * Project: argon-dashboard-react
 * File Created: Wednesday, 25th August 2021 8:10:02 am
 * Author: Ananda Yudhistira (anandabayu12@gmail.com)
 * -----
 * Last Modified: Sunday, 12th September 2021 6:28:13 pm
 * Modified By: Ananda Yudhistira (anandabayu12@gmail.com>)
 * -----
 * Copyright 2021 Ananda Yudhistira, FAN Integrasi Teknologi, PT
 */
import React, { useState, useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Button,
  Col,
  Modal,
  UncontrolledTooltip,
} from 'reactstrap';
import NumberFormat from 'react-number-format';
import moment from 'moment';

// core components
import Header from '../../../components/Headers/Header.js';
import firebase from '../../../config/firebase';
import { Link } from 'react-router-dom';

export default function Auction() {
  moment.locale('id');
  const [auction, setAuction] = useState([]);
  const [modalRemove, setModalRemove] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [selectedName, setSelectedName] = useState();
  const [copiedText, setCopiedText] = useState();

  useEffect(() => {
    const unsubscribe = firebase.db
      .collection(`auction`)
      .orderBy('createdAt', 'desc')
      .onSnapshot((snapshot) => {
        if (snapshot.size) {
          const data = [];
          snapshot.forEach((doc) => data.push({ ...doc.data(), id: doc.id }));
          setAuction(data);
        } else {
          setAuction([]);
        }
      });

    // const cityRef = firebase.db.collection(`auction`).doc('338431650-6403');

    // const doc = await cityRef.get();
    // if (!doc.exists) {
    //   console.log('No such document!');
    // } else {
    //   console.log('Document data:', doc.data());
    // }

    return () => {
      unsubscribe();
    };
  }, []);

  const removeDoc = async () => {
    await firebase.db
      .collection(`auction`)
      .doc(selectedId)
      .collection(`peserta`)
      .onSnapshot((snapshot) => {
        snapshot.forEach(async (e) => {
          await firebase.db
            .collection('auction')
            .doc(selectedId)
            .collection(`peserta`)
            .doc(e.id)
            .delete();
        });
      });

    await firebase.db.collection('auction').doc(selectedId).delete();

    setSelectedName();
    setSelectedId();
    setModalRemove(false);
  };

  const list =
    auction.length > 0 ? (
      auction.map((a, i) => {
        return (
          <tr key={a.id}>
            <td className="text-center">{i + 1}</td>
            <td>{a.name}</td>
            <td className="text-right">
              <NumberFormat
                value={a.hps}
                displayType={'text'}
                thousandSeparator={'.'}
                decimalSeparator={','}
                prefix={'Rp. '}
              />
            </td>
            <td className="text-center">
              {moment(a.startDate).format('DD MMM yyyy HH:mm')}
            </td>
            <td className="text-center">
              {moment(a.endDate).format('DD MMM yyyy HH:mm')}
            </td>

            <td className="text-center">
              <Link to={`/admin/auction/detail/${a.id}`}>
                <Button
                  color="success"
                  type="button"
                  onClick={() => {}}
                  size="md"
                >
                  <i className="ni ni-paper-diploma" />
                </Button>
              </Link>{' '}
              <Button
                color="danger"
                type="button"
                onClick={() => {
                  setSelectedId(a.id);
                  setSelectedName(a.name);
                  setModalRemove(true);
                }}
                size="md"
              >
                <i className="ni ni-fat-remove" />
              </Button>
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td className="text-center" colSpan="7">
          Tidak ada data
        </td>
      </tr>
    );

  return (
    <>
      <Header />

      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Data Auction</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Link to="/admin/auction/add">
                      <Button color="primary" size="sm">
                        Tambah
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col" className="text-center">
                      No
                    </th>
                    <th scope="col" className="text-center">
                      Nama Pengadaan
                    </th>
                    <th scope="col" className="text-center">
                      HPS
                    </th>
                    <th scope="col" className="text-center">
                      Tanggal Mulai
                    </th>
                    <th scope="col" className="text-center">
                      Tanggal Selesai
                    </th>
                    <th scope="col" className="text-center">
                      Aktifitas
                    </th>
                  </tr>
                </thead>
                <tbody>{list}</tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>

      <Modal
        className="modal-dialog-centered"
        size="sm"
        isOpen={modalRemove}
        toggle={() => setModalRemove(!modalRemove)}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Hapus Data
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setModalRemove(!modalRemove)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <p>
            Apakah anda yakin akan menghapus data dengan nama: {selectedName}
          </p>
        </div>
        <div className="modal-footer">
          <Button color="danger" type="button" onClick={() => removeDoc()}>
            Hapus
          </Button>
          <Button
            className="ml-auto"
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={() => setModalRemove(!modalRemove)}
          >
            Close
          </Button>
        </div>
      </Modal>
    </>
  );
}
