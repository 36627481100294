/*
 * File: AddAuction.js
 * Project: e-auction-demo
 * File Created: Wednesday, 25th August 2021 12:25:25 pm
 * Author: Ananda Yudhistira (anandabayu12@gmail.com)
 * -----
 * Last Modified: Saturday, 16th October 2021 10:18:49 am
 * Modified By: Ananda Yudhistira (anandabayu12@gmail.com>)
 * -----
 * Copyright 2021 Ananda Yudhistira, FAN Integrasi Teknologi, PT
 */
import React, { useState } from 'react';
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Button,
  Col,
  CardBody,
  Form,
  FormGroup,
  Input,
  CardFooter,
  Modal,
} from 'reactstrap';
import NumberFormat from 'react-number-format';

// core components
import Header from '../../../components/Headers/Header.js';
import firebase from '../../../config/firebase';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

export default function AddAuction() {
  let history = useHistory();
  const withValueLimit = ({ floatValue }) =>
    floatValue >= 0 && floatValue <= 100;

  const [pengadaan, setPengadaan] = useState();
  const [hps, setHps] = useState();
  const [nego, setNego] = useState(5);
  const [teknisi, setTeknisi] = useState(80);
  const [finansial, setFinansial] = useState(20);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [peserta, setPeserta] = useState([]);
  const [files, setFiles] = useState([]);
  const [modalAdd, setModalAdd] = useState(false);
  const [modalFile, setModalFile] = useState(false);

  const [perusahaan, setPerusahaan] = useState('');
  const [perwakilan, setPerwakilan] = useState('');
  const [jabatan, setJabatan] = useState('');
  const [teknis, setTeknis] = useState('');
  const [check, setCheck] = useState(false);

  const [namaFile, setNamaFile] = useState('');

  const countPresentase = (value, isTeknis) => {
    if (isTeknis) {
      setTeknisi(value);
      setFinansial(100 - value);
    } else {
      setFinansial(value);
      setTeknisi(100 - value);
    }
  };

  const addPeserta = () => {
    if (
      perusahaan.trim() === '' ||
      perwakilan.trim() === '' ||
      jabatan.trim() === '' ||
      teknis.trim() === ''
    ) {
      return;
    }

    let data = {
      perusahaan,
      perwakilan,
      jabatan,
      teknis,
      check,
      username: getRandomInt(99999).toString(),
      password: getRandomInt(99999).toString(),
      penawaran: [],
      bestValue: 0.0,
    };

    setPeserta([...peserta, data]);

    clearModalInput();
    setModalAdd(false);
  };

  const clearModalInput = () => {
    setPerusahaan('');
    setPerwakilan('');
    setJabatan('');
    setTeknis('');
    setCheck(false);
  };

  const addFile = () => {
    if (namaFile.trim() === '') {
      return;
    }

    let data = {
      name: namaFile,
      url: null,
    };

    setFiles([...files, data]);

    setNamaFile('');
    setModalFile(false);

    console.log(files);
  };

  const handleRemoveItem = (name) => {
    setPeserta(peserta.filter((item) => item.perusahaan !== name));
  };

  const handleRemoveFile = (name) => {
    setFiles(files.filter((item) => item.name !== name));
  };

  function getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }

  const submit = async () => {
    let data = {
      name: pengadaan,
      hps: parseInt(hps),
      nego,
      teknisi,
      finansial,
      startDate,
      endDate,
      // peserta,
      createdAt: firebase.firebase.firestore.Timestamp.fromDate(new Date()),
      winnerValue: 0.1,
      winnerId: '',
    };

    let docId = `${getRandomInt(1000000000)}-${getRandomInt(9999)}`;

    const fb = firebase.db.collection('auction').doc(docId);
    fb.set(data)
      .then((res) => {
        console.log('Document successfully written!');

        peserta.forEach(async (e) => {
          e['files'] = files;
          await firebase.db.collection(`auction/${docId}/peserta`).add(e);
        });

        history.push('/admin/auction/index');
      })
      .catch((error) => {
        console.error('Error writing document: ', error);
      });
  };

  const listPeserta =
    peserta.length > 0 ? (
      peserta.map((a, i) => {
        return (
          <tr key={a.id}>
            <td className="text-center">{i + 1}</td>
            <td>{a.perusahaan}</td>
            <td className="text-center">{a.perwakilan}</td>
            <td className="text-center">{a.jabatan}</td>
            <td className="text-center">{a.teknis}</td>
            <td className="text-center">
              <Button
                color="danger"
                type="button"
                onClick={() => handleRemoveItem(a.perusahaan)}
                size="sm"
              >
                <i className="ni ni-fat-remove" />
              </Button>
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td className="text-center" colSpan="6">
          Belum ada peserta
        </td>
      </tr>
    );

  const listFile =
    files.length > 0 ? (
      files.map((a, i) => {
        return (
          <tr key={a.id}>
            <td className="text-center">{i + 1}</td>
            <td className="text-center">{a.name}</td>
            <td className="text-center">
              <Button
                color="danger"
                type="button"
                onClick={() => handleRemoveFile(a.name)}
                size="sm"
              >
                <i className="ni ni-fat-remove" />
              </Button>
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td className="text-center" colSpan="3">
          Belum ada file
        </td>
      </tr>
    );

  return (
    <>
      <Header />

      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Tambah Auction</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label className="form-control-label">
                          Nama Pengadaan.
                        </label>
                        <Input
                          value={pengadaan || ''}
                          onChange={(e) => setPengadaan(e.target.value)}
                          placeholder="Nama Pengadaan."
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label className="form-control-label">HPS</label>
                        <NumberFormat
                          value={hps}
                          className="form-control"
                          placeholder="Rp. xxx.xxx,xx"
                          thousandSeparator="."
                          decimalSeparator=","
                          prefix={'Rp. '}
                          onValueChange={(values) => {
                            const { value } = values;
                            setHps(value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label className="form-control-label">
                          Target Nego
                        </label>
                        <NumberFormat
                          value={nego}
                          className="form-control"
                          placeholder="5%"
                          suffix="%"
                          isAllowed={withValueLimit}
                          onValueChange={(values) => {
                            const { value } = values;
                            setNego(value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label className="form-control-label">
                          Presentase Teknis
                        </label>
                        <NumberFormat
                          value={teknisi}
                          className="form-control"
                          placeholder="50%"
                          suffix="%"
                          isAllowed={withValueLimit}
                          onValueChange={(values) => {
                            const { value } = values;
                            countPresentase(value, true);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label className="form-control-label">
                          Presentase Finansial
                        </label>
                        <NumberFormat
                          value={finansial}
                          className="form-control"
                          placeholder="50%"
                          suffix="%"
                          isAllowed={withValueLimit}
                          onValueChange={(values) => {
                            const { value } = values;
                            countPresentase(value, false);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">
                    Jadwal Auction
                  </h6>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <label className="form-control-label">
                          Tanggal Mulai
                        </label>
                        <Input
                          value={startDate}
                          placeholder="Tanggal mulai."
                          type="datetime-local"
                          min={new Date().toISOString().slice(0, -8)}
                          onChange={(e) => setStartDate(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label className="form-control-label">
                          Tanggal Selesai
                        </label>
                        <Input
                          value={endDate}
                          placeholder="Tanggal mulai."
                          type="datetime-local"
                          min={startDate}
                          onChange={(e) => setEndDate(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <hr className="my-4" />

                  {/* Peserta Section */}
                  <Row>
                    <Col xs="8">
                      <h6 className="heading-small text-muted mb-4">Peserta</h6>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Button
                        color="primary"
                        size="sm"
                        onClick={() => setModalAdd(true)}
                      >
                        Tambah
                      </Button>
                      {/* Modal */}
                      <Modal
                        className="modal-dialog-centered"
                        size="lg"
                        isOpen={modalAdd}
                        toggle={() => setModalAdd(!modalAdd)}
                      >
                        <div className="modal-body p-0">
                          <Card className="bg-secondary shadow border-0">
                            <CardBody>
                              <Form role="form">
                                <Row>
                                  <Col md="12">
                                    <label className="form-control-label">
                                      Nama Perusahaan
                                    </label>
                                    <FormGroup>
                                      <Input
                                        value={perusahaan}
                                        onChange={(e) =>
                                          setPerusahaan(e.target.value)
                                        }
                                        placeholder="Nama Perusahaan"
                                        type="text"
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md="6">
                                    <FormGroup>
                                      <label className="form-control-label">
                                        Nama Perwakilan
                                      </label>
                                      <Input
                                        value={perwakilan}
                                        onChange={(e) =>
                                          setPerwakilan(e.target.value)
                                        }
                                        placeholder="Nama Perwakilan"
                                        type="text"
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md="6">
                                    <FormGroup>
                                      <label className="form-control-label">
                                        Jabatan
                                      </label>
                                      <Input
                                        value={jabatan}
                                        onChange={(e) =>
                                          setJabatan(e.target.value)
                                        }
                                        placeholder="Jabatan"
                                        type="text"
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md="6">
                                    <FormGroup>
                                      <label className="form-control-label">
                                        Penilaian Teknis
                                      </label>
                                      <NumberFormat
                                        value={teknis}
                                        className="form-control"
                                        placeholder="Penilaian Teknis"
                                        suffix="%"
                                        isAllowed={withValueLimit}
                                        onValueChange={(values) => {
                                          const { value } = values;
                                          setTeknis(value);
                                        }}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md="6">
                                    <FormGroup>
                                      <label className="form-control-label">
                                        <br />
                                      </label>
                                      <div className="custom-control custom-checkbox mb-3">
                                        <input
                                          className="custom-control-input"
                                          defaultChecked={check}
                                          id="customCheck2"
                                          type="checkbox"
                                          onChange={() => setCheck(!check)}
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="customCheck2"
                                        >
                                          Perwakilan Selain Direktur Wajib
                                          melampirkan Surat Kuasa
                                        </label>
                                      </div>
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <div className="text-center">
                                  <Button
                                    className="my-4"
                                    color="primary"
                                    type="button"
                                    onClick={addPeserta}
                                  >
                                    Tambah
                                  </Button>
                                </div>
                              </Form>
                            </CardBody>
                          </Card>
                        </div>
                      </Modal>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12">
                      <Table
                        className="align-items-center table-flush"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr>
                            <th scope="col" className="text-center">
                              No
                            </th>
                            <th scope="col" className="text-center">
                              Perusahaan
                            </th>
                            <th scope="col" className="text-center">
                              Perwakilan
                            </th>
                            <th scope="col" className="text-center">
                              Jabatan
                            </th>
                            <th scope="col" className="text-center">
                              Teknis
                            </th>
                            <th scope="col" className="text-center">
                              #
                            </th>
                          </tr>
                        </thead>
                        <tbody>{listPeserta}</tbody>
                      </Table>
                    </Col>
                  </Row>
                  <hr className="my-4" />

                  {/* File Section */}
                  <Row>
                    <Col xs="4">
                      <h6 className="heading-small text-muted mb-4">
                        Kebutuhan File
                      </h6>
                    </Col>
                    <Col className="text-right" xs="2">
                      <Button
                        color="primary"
                        size="sm"
                        onClick={() => setModalFile(true)}
                      >
                        Tambah
                      </Button>
                      {/* Modal */}
                      <Modal
                        className="modal-dialog-centered"
                        size="lg"
                        isOpen={modalFile}
                        toggle={() => setModalFile(!modalFile)}
                      >
                        <div className="modal-body p-0">
                          <Card className="bg-secondary shadow border-0">
                            <CardBody>
                              <Form role="form">
                                <Row>
                                  <Col md="12">
                                    <label className="form-control-label">
                                      Nama File
                                    </label>
                                    <FormGroup>
                                      <Input
                                        value={namaFile}
                                        onChange={(e) =>
                                          setNamaFile(e.target.value)
                                        }
                                        placeholder="Nama File"
                                        type="text"
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>

                                <div className="text-center">
                                  <Button
                                    className="my-4"
                                    color="primary"
                                    type="button"
                                    onClick={addFile}
                                  >
                                    Tambah
                                  </Button>
                                </div>
                              </Form>
                            </CardBody>
                          </Card>
                        </div>
                      </Modal>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="6">
                      <Table
                        className="align-items-center table-flush"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr>
                            <th scope="col" className="text-center">
                              No
                            </th>
                            <th scope="col" className="text-center">
                              Nama File
                            </th>
                            <th scope="col" className="text-center">
                              #
                            </th>
                          </tr>
                        </thead>
                        <tbody>{listFile}</tbody>
                      </Table>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
              <CardFooter>
                <Row>
                  <Col xs="6">
                    <Link to="/admin/auction/index">
                      <Button color="danger" size="md">
                        Batal
                      </Button>
                    </Link>
                  </Col>
                  <Col className="text-right" xs="6">
                    <Button
                      color="success"
                      size="md"
                      onClick={submit}
                      disabled={
                        !pengadaan ||
                        !hps ||
                        !startDate ||
                        !endDate ||
                        peserta.length < 2 ||
                        files.length < 1
                      }
                    >
                      Simpan
                    </Button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
