/*
 * File: App.js
 * Project: argon-dashboard-react
 * File Created: Wednesday, 25th August 2021 5:32:08 am
 * Author: Ananda Yudhistira (anandabayu12@gmail.com)
 * -----
 * Last Modified: Monday, 30th August 2021 11:40:36 am
 * Modified By: Ananda Yudhistira (anandabayu12@gmail.com>)
 * -----
 * Copyright 2021 Ananda Yudhistira, FAN Integrasi Teknologi, PT
 */
import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import AdminLayout from 'layouts/Admin.js';
import AuthLayout from 'layouts/Auth.js';
import Vendor from 'views/pages/auction/Vendor';

export default function App() {
  // Declare a new state variable, which we'll call "count"
  const [isAuth, setAuth] = useState(false);

  const detectStorageChange = () => {
    var auth = localStorage.getItem('isAuth');
    if (auth === 'true') {
      setAuth(true);
    } else {
      setAuth(false);
    }
  };

  useEffect(() => {
    detectStorageChange();
    window.addEventListener('storage', detectStorageChange);

    return () => window.removeEventListener('storage', detectStorageChange);
  }, []);

  const appRoute = () => {
    if (isAuth) {
      return (
        <BrowserRouter>
          <Switch>
            <Route
              path="/admin"
              render={(props) => <AdminLayout {...props} />}
            />
            <Redirect from="/" to="/admin/index" />
          </Switch>
        </BrowserRouter>
      );
    } else {
      return (
        <BrowserRouter>
          <Switch>
            <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
            <Route
              path="/auction/:id"
              render={(props) => <Vendor {...props} />}
            />
            <Redirect from="/" to="/auth/login" />
          </Switch>
        </BrowserRouter>
      );
    }
  };

  return <div>{appRoute()}</div>;
}
