/*
 * File: DetailAuction.js
 * Project: e-auction-demo
 * File Created: Sunday, 12th September 2021 5:24:14 pm
 * Author: Ananda Yudhistira (anandabayu12@gmail.com)
 * -----
 * Last Modified: Saturday, 16th October 2021 11:49:23 am
 * Modified By: Ananda Yudhistira (anandabayu12@gmail.com>)
 * -----
 * Copyright 2021 Ananda Yudhistira, FAN Integrasi Teknologi, PT
 */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Col,
  Container,
  Row,
  Table,
} from 'reactstrap';
import NumberFormat from 'react-number-format';
import moment from 'moment';

import firebase from 'config/firebase';

const DetailAuction = (props) => {
  moment.locale('id');
  let { id } = useParams();

  const mainContent = React.useRef(null);

  const [auction, setAuction] = useState({});

  const [peserta, setPeserta] = useState([]);

  useEffect(() => {
    const unsubscribe = firebase.db
      .collection(`auction`)
      .doc(id)
      .onSnapshot((snapshot) => {
        console.log('AUCTION');
        setAuction(snapshot.data());
      });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const unsubscribe = firebase.db
      .collection(`auction`)
      .doc(id)
      .collection(`peserta`)
      .orderBy('bestValue', 'desc')
      .onSnapshot((snapshot) => {
        console.log('PESERTA');
        if (snapshot.size) {
          const data = [];
          snapshot.forEach((doc) => data.push({ ...doc.data(), id: doc.id }));
          setPeserta(data);
        } else {
          setPeserta([]);
        }
      });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <>
      <div className="main-content" ref={mainContent}>
        <div className="header bg-gradient-info py-7 py-lg-8"></div>
        {/* Page content */}
        <Container className="mt--8 pb-5">
          <Card className="shadow">
            <CardHeader className="border-0">
              <Row className="align-items-center">
                <Col md="8">
                  <h3 className="mb-0">
                    {id} - {auction.name}
                  </h3>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="12">
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col" className="text-center">
                          HPS
                        </th>
                        <th scope="col" className="text-center">
                          Tanggal Mulai
                        </th>
                        <th scope="col" className="text-center">
                          Tanggal Selesai
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-center">
                          <NumberFormat
                            value={auction.hps}
                            displayType={'text'}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            prefix={'Rp. '}
                          />
                        </td>
                        <td className="text-center">
                          {moment(auction.startDate).format(
                            'DD MMM yyyy HH:mm'
                          )}
                        </td>
                        <td className="text-center">
                          {moment(auction.endDate).format('DD MMM yyyy HH:mm')}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <hr className="my-3" />
              <Row>
                <Col md="12">
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col" className="text-center">
                          Peserta
                        </th>
                        <th scope="col" className="text-center">
                          Nilai
                        </th>
                        <th scope="col" className="text-center">
                          File
                        </th>
                        <th scope="col" className="text-center">
                          Status
                        </th>
                        <th scope="col" className="text-center">
                          Akses
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {peserta.map((p, i) => {
                        return (
                          <tr key={i}>
                            <td>{p.perusahaan}</td>
                            <td className="text-center">{p.bestValue}</td>
                            <td>
                              {p.files.map((f) => {
                                return (
                                  <div className="mt-1">
                                    {f.url === null || f.url === '' ? (
                                      `${f.name} - Not Uploaded`
                                    ) : (
                                      <a href={f.url} target="_blank">
                                        {f.name} <i className="ni ni-send" />
                                      </a>
                                    )}
                                  </div>
                                );
                              })}
                            </td>
                            <td className="text-center">
                              {p.id === auction.winnerId ? 'Pemenang' : ''}
                            </td>
                            <td className="text-center">
                              <CopyToClipboard
                                text={`Link: ${window.location.origin}/auction/${id}\nUsername: ${p.username}\nPassword: ${p.password}`}
                              >
                                <Button color="primary" size="sm">
                                  Copy Akses
                                </Button>
                              </CopyToClipboard>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Col>
              </Row>

              <hr className="my-3" />
              <Row>
                {peserta.map((p, i) => {
                  return (
                    <Col md="3">
                      <Table
                        className="align-items-center table-flush"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr>
                            <th scope="col" className="text-center">
                              {p.perusahaan}{' '}
                              {p.id === auction.winnerId ? (
                                <i className="ni ni-trophy ni-lg text-success" />
                              ) : (
                                ''
                              )}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {p.penawaran.map((pp, i) => {
                            return (
                              <tr key={i}>
                                <td className="text-right">
                                  <NumberFormat
                                    value={pp}
                                    displayType={'text'}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    prefix={'Rp. '}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </Col>
                  );
                })}
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
      {/* <AuthFooter /> */}
    </>
  );
};

export default DetailAuction;
